import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Test from '../Test';
import logo from '../../images/logo.png'
import nav  from '../../images/hamburger.svg'
import location  from '../../images/location.svg'
import arrow_black from '../../images/arrow_black.svg'
import search from '../../images/search.svg'
import M from "materialize-css/dist/js/materialize.min.js";
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_URL } from '../../Apis/config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const Header = () => {
  const Stat1 = cookies.get('State');
  const CountryCode = cookies.get('Country');
  const CName = cookies.get('CountryName');
  const Statevalue = cookies.get('Statevalue');
  const [state, setstate] = useState([]);
  const [CityData,SetCityData] = useState("")
  const [Country,SetCountry] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedstate, setSelectedstate] = useState("");
  const [SelectedCountry,SetSelectedCountry] = useState(null);
  const [SelectedCountryId,SetSelectedCountryId] = useState(null)
  const [SelectedCity,SetSelectedCity] = useState("")
  const [SelectedCityId,SetSelectedCityId] = useState("")
  const CountryStatus=!(SelectedCountry)?CountryCode:SelectedCountry;
  const Statte=!(selectedstate.code)?Statevalue:selectedstate.code;
  useEffect(() => {
    const sidenav = document.querySelectorAll(".sidenav");
    M.Sidenav.init(sidenav, {
      isOpen: true
    });
  });
  useEffect(() => {
    fetch(`${BASE_URL}/allcountry`, {
      method: 'GET'
    }).then((res) => {
      return res.json();
    }).then((data) => {
      SetCountry(data.result)
    }).catch((err) => {
      console.log(err.message);
    })
  },[isPopupOpen]);
  useEffect(() => {
      fetch(`${BASE_URL}/getstate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          country_code: !(SelectedCountryId)?CountryCode:SelectedCountryId
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        cookies.set('State',data.result);
        setstate(data.result)
      }).catch((err) => {
        console.log(err.message);
      })
    },[SelectedCountry]);
    useEffect(() => {
      fetch(`${BASE_URL}/citybystateid`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          state_code: selectedstate.code
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        SetCityData(data)
        cookies.set('City',data);
      }).catch((err) => {
        console.log(err.message);
      })
    },[selectedstate]);
  const togglePopup = (type) => {
    setIsPopupOpen(!isPopupOpen);
    setIsPopupOpen1(!isPopupOpen1)
  };
  const ClosePopup = (type) => {
    if(type&&SelectedCountry&&isPopupOpen){
      window.location.reload();
    }
    setIsPopupOpen(!isPopupOpen);
  };
  const togglePopup1 = () => {
    setIsPopupOpen1(!isPopupOpen1);
  };
  const togglePopup2 = () => {
    setIsPopupOpen2(!isPopupOpen2);
  };
  const togglePopup3 = () => {
    setIsPopupOpen3(!isPopupOpen3);
  };
  const showtoggle = () => {
    settoggle(!toggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredState = (state)?state.filter((state) =>
    state.name.toLowerCase().includes(searchTerm.toLowerCase())):[];

  const filteredCountries = (Country)?Country.filter((state) =>
    state.name.toLowerCase().includes(searchTerm.toLowerCase())):[];
    
  const filteredCity = (CityData)?CityData.filter((state) =>
    state.name.toLowerCase().includes(searchTerm.toLowerCase())):[];
  const selectCountry = (name,code,currency,currencycode) => {
    document.getElementById('country_list').style.display = "none";
    cookies.set('Country',code, { path: '/' });
    cookies.set('CountryName',name, { path: '/' });
    cookies.set('Currency',currency, { path: '/' });
    cookies.set('Currencycode',currencycode, { path: '/' });
    SetSelectedCountry(name);
    SetSelectedCountryId(code);
    setSelectedstate('')
    setIsPopupOpen1(false)
    setIsPopupOpen2(!isPopupOpen2)
    cookies.set('Statevalue',null,{ path: '/' });
    cookies.set('CityId',null, { path: '/' });
    cookies.set('Statename',null, { path: '/' });
    cookies.set('CityName',null, { path: '/' });
  };
  const selectstate = (states) => {
    document.getElementById('state_list').style.display = "none";
    setSelectedstate(states);
    cookies.set('Statevalue',states.code);
    cookies.set('Statename',states.name);
    setIsPopupOpen3(!isPopupOpen3)
    setIsPopupOpen2(!isPopupOpen2)
  };
  const selectCity = (City) => {
    SetSelectedCity(City);
    cookies.set('CityId',City.id, { path: '/' });
    cookies.set('CityName',City.name, { path: '/' });
    setIsPopupOpen3(!isPopupOpen3)
    window.location.reload();
  };
  function formatContent(content) {
    return content.length > 10 ? content.slice(0,10) : content;
}
  return (
    <>
<div className="header navbar-fixed">
  <nav className="top_nav">
    <div className="h_left">
      <a onClick={showtoggle}
      data-target="mobile-nav"
        className="sidenav-trigger a_logo "
      >
        <img src={nav} alt="menu" />
      </a>
      <Link to={'/'} className="a_logo">
        <img src={logo} alt="logo" className="logo" />
      </Link>
    </div>
    <div className="h_right">
      <a onClick={togglePopup} className=" location_btn roboto_regular ft_14">
        <img src={location} alt={location} />
       {!(SelectedCountry)?CName:SelectedCountry}
        <img src={arrow_black} alt={location} /> 
      </a>
    </div>
    {isPopupOpen && (
        <div className="popup">
          <div className="popup-header">
            <div class="search mt_10">
              <img src={search} alt="search" />
              <input type="search" placeholder="Search..." value={searchTerm}
              onChange={handleSearchChange}
              className="search-input" />
            </div>
             <button onClick={() =>ClosePopup(true)} className='cross'>
                ✖
              </button>
          </div>
          <div className='d_flex gap2'>
          <div className='center change_country'>
              <a  onClick={togglePopup1} class=" location_btn roboto_regular ft_14"> {(!SelectedCountry)?'Country':formatContent(SelectedCountry)}...<img src={arrow_black} alt={arrow_black} /></a>
            </div>
            <div className='center change_country'>
              <a  onClick={togglePopup2} class=" location_btn roboto_regular ft_14">{!(selectedstate.name)?'State':formatContent(selectedstate.name)}...<img src={arrow_black} alt={arrow_black} /></a>
            </div>
            <div className='center change_country'>
              <a  onClick={togglePopup3} class=" location_btn roboto_regular ft_14">{!(SelectedCity.name)?'City':formatContent(SelectedCity.name)}...<img src={arrow_black} alt={arrow_black} /></a>
            </div>
          </div>
        {isPopupOpen1&& <div>
            <p class="roboto_medium d_black ft_14">Select Country</p>
            <ul className="country-list" id="country_list">
           {filteredCountries&& filteredCountries.map((country, index) => (
              <li
                key={index}
                className="country-item"
                onClick={() => selectCountry(country.name,country.code,country.html_entity,country.currency_code)}
              >
                {country.name}
              </li>
            ))}
            
          </ul>
          </div>
        }
          {(isPopupOpen2)&& 
          <div>
          <p class="roboto_medium d_black ft_14">Select State</p>
          <ul className="country-list" id='state_list'>
           {filteredState&& filteredState.map((states, index) => (
              <li
                key={index}
                className="country-item"
                onClick={() => selectstate(states)}
              >
                {states.name}
              </li>
            ))}
           
          </ul>
          </div>
          }
          {(isPopupOpen3)&&<div>
            <p class="roboto_medium d_black ft_14">Select City</p>
            <ul className="country-list">
           {filteredCity&& filteredCity.map((City, index) => (
              <li
                key={index}
                className="country-item"
                onClick={() => selectCity(City)}
              >
                {City.name}
              </li>
            ))}
            
          </ul>
          </div>   
         }
        </div>
      )}
  </nav>
  <ul  id="mobile-nav" className="sidenav">
          <li><Link to={'/dashboard'} className="sidenav-close">Dashboard</Link></li>  
          <li><Link to={'/AddProduct'} className="sidenav-close">Post AD</Link></li>
          {/* <li><Link to={'/MyListing'} className="sidenav-close">My Listing</Link></li> */}
          <li><Link to={'/Favourite'} className="sidenav-close">Favourite</Link></li>
          {/* <li><div class="divider"></div></li>
          <li><a class="waves-effect" href="#!" className="sidenav-close">FAQ</a></li>
          <li><a class="waves-effect" href="#!" className="sidenav-close">Privacy Policy</a></li>
          <li><a class="waves-effect" href="#!" className="sidenav-close">Terms & Conditions</a></li> */}
        </ul>
</div>

    </>
  )
}

export default Header
