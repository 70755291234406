import React, { useEffect, useState } from 'react'
import Ads from '../Ads/Index'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Search from '../Header/Search';
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import location from '../../images/location_light.svg'
import { useLocation, useParams } from 'react-router-dom';
import {handleShare} from '../../Pages/Share'
import like from '../../images/liked.svg'
import Premiumad from '../Ads/Premiumad';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const SearchData = () => {
  const Currency = !cookies.get('Currency')?"&#8358":cookies.get('Currency');
    const {slug}=useParams()
    const id = cookies.get('Id');
      const[FilteredData,SetFilteredData]=useState([])
      const CountryCode = cookies.get('Country');
      const CountryCodeName = cookies.get('CountryName');
      const City = cookies.get('CityId');
     useEffect(() => {
           fetch(`${BASE_URL}/listing?&location=${CountryCodeName}&latitude=&longitude=&placetype=country&placeid=${CountryCode}&cityid=${City}&keywords=${slug}&cat=&subcat`, {
             method: 'GET',
           }).then((res) => {
             return res.json();
           }).then((data) => {
             SetFilteredData(data.result.posts.data)
           }).catch((err) => {
             console.log(err.message);
           })
         },[slug]);
  const queryClient = useQueryClient();
  const res = queryClient.getQueryData(['Related']);
  return (
  <>
  <div className="body_area">
  <Search></Search>
  {FilteredData.length==0&&<p className="ft_14 mt_10 roboto_regular d_lblack">
    Sorry, we couldn't find any matches. <br />Showing results for {slug}
        </p>}
        {/* list*/}
        <div className="list_items mt_20">
          {/* featured list*/}
          {FilteredData&&FilteredData.map((ele, i) => {             
 return (
  <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Ads>
)
})
}  
{FilteredData.length==0&&<p className="ft_18 roboto_medium">Suggested Listing</p>
}{FilteredData.length==0&&res.map((ele, i) => {   
 return (
  <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Ads>
)
})
} 
</div>
      </div>
  </>
  )
}

export default SearchData
