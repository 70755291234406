import React, { useEffect, useState } from 'react'
import Add from '../../images/add.svg'
import Camera from '../../images/camera.svg'
import { BASE_URL } from '../../Apis/config';
import { useNavigate, useParams } from 'react-router-dom';
import verified from '../../images/verified.png'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
const cookies = new Cookies();
const EditProfile = () => { 
     const id = cookies.get('Id');
     const [email, Setemail] = useState('')
      const [name, Setname] = useState('')
      const [mobile, Setmobile] = useState('')
      const [emailstatus, SetEmailstatus] = useState(null)
       const [emailVerify, SetEmailVerify] = useState(null)
        const [Opt_sent, SetOpt_sent] = useState('')
        const [Verifed, SetVerifed] = useState('')
        const [errors, setErrors] = useState({});
        const [otp, Setotp] = useState('')
         const nav=useNavigate()
         const inputs = ["input1", "input2", "input3", "input4", "input5", "input6"];
          const [inputs1, setInputs1] = useState({ input1: "", input2: "", input3: "", input4: "" });
          // Add listeners to each input
          inputs.forEach((id) => {
            const input = document.getElementById(id);
            if (input) {
              addListener(input);
            }
          });
          function addListener(input) {
            input.addEventListener("keyup", (event) => {
              const { key, target } = event; // Destructure key and target from event
              const code = parseInt(target.value, 10);
        
              // Move to the next input on valid number (0-9)
              if (code >= 0 && code <= 9) {
                const next = input.nextElementSibling;
                if (next) next.focus();
              } else {
                target.value = ""; // Clear invalid input
              }
        
              // Handle backspace or delete: move to previous input
              if (key === "Backspace" || key === "Delete") {
                const prev = input.previousElementSibling;
                if (prev) prev.focus();
              }
            });
          }


           const success=(message)=>{
              Swal.fire({
                title: 'Profile updated successfully',
                // text: "Please wait for 24 hours to approve your item",
                icon: "success"
              }).then((result) => {
                if (result.isConfirmed) {
                    nav(`/dashboard/`)
                  };               });
            }
   useEffect(() => {
      fetch(`${BASE_URL}/getprofile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: id,
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        Setemail(data.result[0].email)
        Setname(data.result[0].name)
        Setmobile(data.result[0].mobile_number)
        SetEmailstatus(data.result[0].is_email_varify) 
      }).catch((err) => {
        console.log(err.message);
      })
    },[]);
     const SendOtp=()=>{
        fetch(`${BASE_URL}/varifyemail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: id,
            email : email
        })
        }).then((res) => {
          return res.json();
        }).then((data) => {
          SetOpt_sent(data.status)
        }).catch((err) => {
          console.log(err.message);
        })
      }
      const handleChange = (e) => {
        const { id, value } = e.target;
        setInputs1((prevState) => ({
          ...prevState,
          [id]: value, // Dynamically update the specific input's value
        }));
      };


       const Otp1 = Object.values(inputs1).join("");
        const VerifyMailFucntion = () => {
          fetch(`${BASE_URL}/varifyotp`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              user_id: id,
              otp: Otp1
            })
          }).then((res) => {
            return res.json();
          }).then((data) => {
            SetEmailVerify(data.status)
            if (data.status == 1) {
              SetVerifed(1)
            }
          }).catch((err) => {
            console.log(err.message);
          })
        }


         const UserDetails = {
            user_id: id,
            name: name,
            email: email,
            mobile_number: mobile
          }
         const update = async (e) => {
          const newErrors = validateForm({name,email,mobile});
          setErrors(newErrors);
          if(Object.keys(newErrors).length <1){
            try {
              const response = await axios.post(`${BASE_URL}/updateprofile`, UserDetails, {
                headers: { 'Content-Type': 'application/json' },
              });
              if(response.status==200){
                      success()
              }
            } catch (error) {
              console.error("Error :", error);
            }
          }
        }
          const validateForm = (data) => {
            const errors = {};
            if (!data.name.trim()) {
              errors.name = 'title is required';
            } else if (data.name.length<5) {
              errors.name = 'The Comment must be at least 5 characters.';
            }
            if (!data.email.trim()) {
              errors.email = 'Email is required';
            } else if (data.email.length<5) {
              errors.email = 'The Email must be at least 5 characters.';
            }
            if (!data.mobile.trim()) {
              errors.mobile = 'Mobile no  is required';
            } else if (data.mobile.length<7 || data.mobile.length>15) {
              errors.mobile = 'The Mobile no between  7 to 15  characters.';
            }
            return errors;
          };
  return (
    <div  className="body_area grid_main">
            <div className='left_a'>

                    <p className='ft_18 roboto_medium d_black mt_20'> User Details</p>
                    <form >
                    {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}


{/* .....Image Upload... */}
                {/* <div class="mt_10 d_center">
                    <div class="circle22 p_image img_user " >
                        <img class="profile-pic" src={Camera} ></img>
                        </div>
                    <div class="change_img" >
                        <img class="fa fa-camera upload-button" src={Camera} ></img>
                        <input class="file-upload" type="file" accept="image/*" id="fileInput" />
                    </div>
                </div> */}
                  <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Name</p>
                        <input placeholder="Enter your name" id="first_name" type="text" class="validate"  value={name}  name="name"onChange={(e) => Setname(e.target.value)} />
                        {errors.name && (
                <span className="error-message">
                  {errors.name}
                </span>
              )}
                    </div>

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Phone Number</p>
                        <input id="icon_telephone" type="number" class="validate"name='mobile' value={mobile} onChange={(e) => Setmobile(e.target.value)}/>
                        {errors.mobile && (
                <span className="error-message">
                  {errors.mobile}
                </span>
              )}
                    </div>
                    <div class="input-field col s12 ">
                        <p className='ft_14 roboto_medium d_black mt_10'>Email</p>
                      { parseInt(emailstatus)==1&&<div className="send_otp_btn">
                        <input id="icon_telephone" type="email" class="validate"name='email' value={email}disabled />
                        <a className='pr_btn roboto_regular ft_14 verified'><img src={verified} alt=" " class="btn_img" />Verified</a>
                        </div>}
                        { parseInt(emailstatus)==0&&<div className="send_otp_btn">
                        <input id="icon_telephone" type="email" class="validate"name='email' value={email}onChange={(e) => Setemail(e.target.value)}/>
                        {errors.email && (
                <span className="error-message">
                  {errors.email}
                </span>
              )}
                        {Opt_sent!=1&&<a className='pr_btn roboto_regular ft_14 ' onClick={SendOtp} >Send OTP</a>}
                        {Opt_sent==1&&<a className='pr_btn roboto_regular ft_14  otp_sent' >OTP Sent</a>}
                        </div>}  
                        {Opt_sent == 1 &&
                <div className=''>
                  <p class="ft_14 roboto_medium d_black mt_10">Enter OTP</p>
                  <p class="ft_10 roboto_regular d_lblack mt_10">We have sent a verification code to your Email ID</p>
                  <div id="inputs" class="mt_10">
                    <input id="input1" type="number" maxlength="1" value={inputs.input1} onChange={handleChange} />
                    <input id="input2" type="number" maxlength="1" value={inputs.input2}
                      onChange={handleChange} />
                    <input id="input3" type="number" maxlength="1" value={inputs.input3}
                      onChange={handleChange} />
                    <input id="input4" type="number" maxlength="1" value={inputs.input4}
                      onChange={handleChange} />
                    <input id="input5" type="number" maxlength="1" value={inputs.input5}
                      onChange={handleChange} />
                    <input id="input6" type="number" maxlength="1" value={inputs.input6}
                      onChange={handleChange} />
                  </div>
                  {emailVerify == 0 && <p class="ft_10 roboto_regular d_lblack ">Enter Valid OTP</p>}
                  {emailVerify == 1 && <p class="ft_10 roboto_regular d_lblack ">verified successfully</p>}
{                emailVerify != 1 &&  <a className='btn_full pr_btn roboto_regular waves-effect ft_14 mt_10' onClick={VerifyMailFucntion} >Proceed</a>
}                  {/* <div className='send_otp_btn'>
                    <input  type="number" class="validate" name='otp' placeholder='Enter OTP...' value={otp} onChange={(e) => Setotp(e.target.value)} required maxLength={6} />
                    <a className='pr_btn roboto_regular waves-effect ft_14 ' onClick={VerifyMailFucntion} >Proceed</a>
                  </div> */}
                </div>
              }          
                    </div>
                    <a class="mt_20 btn_full pr_btn roboto_regular  ft_14 mt_10" onClick={update}>    
                       Save Changes
                    </a>
                  </form>  
                    
            </div>
            <div className='right_a'></div>

        </div>       
  )
}

export default EditProfile
