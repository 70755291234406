import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import { handleShare } from '../../../Pages/Share'
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import share from '../../../images/share.svg'
import inactive from '../../../images/like_inactive.svg'
import location from '../../../images/location_light.svg'
import user from '../../../images/user.png'
import timer from '../../../images/timer.svg'
import chat from '../../../images/chat.svg'
import car from '../../../images/car.jpg'
import view from '../../../images/views.svg'
import like from '../../../images/liked.svg'
import star from '../../../images/star.svg'
import starenable from '../../../images/start_Eenable.svg'
import stardisable from '../../../images/start_disable.svg'
import axios from 'axios';
import RelatedProduct from './RelatedProduct';
import { BASE_URL } from '../../../Apis/config';
import { imageUrl1 } from '../../../Apis/config';
import email from '../../../images/email.svg';
import phone from '../../../images/phone.svg';
import M from "materialize-css/dist/js/materialize.min.js";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const ProductDetails = () => {
   // report product 
   const [Reason, SetReason] = useState('');
   const [Email, SetEmail] = useState('');
     const [errors, setErrors] = useState({});
     const [errors1, setErrors1] = useState({});
   useEffect(() => {
      const modal = document.querySelectorAll(".modal");
      if(!Email&&!Reason){
       M.Modal.init(modal, {
          isOpen: true
      });
    }
    },);
    // convert html currency code
  function App({ htmlEntity }) {
    const decodeEntity = (entity) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = entity;
      return textArea.value;
    };
    return <>{decodeEntity(htmlEntity)}</>;
  }

  const Currency = !cookies.get('Currency')?"&#8358":cookies.get('Currency');
  const [image,Setimage]=useState()
  const queryClient = useQueryClient();
  const { id } = useParams()
  const uid = cookies.get('Id');
  // Get Product details
  const Details = async () => {
    const { data } = await axios.get(`${BASE_URL}/ad/${id}/werree`);
    return data;
  };
  const nav = useNavigate()
  const [rating, setRating] = useState(0);
  const [showdetails, Setshowdetails] = useState(false);
  const [comments, setcomments] = useState('Hello This is user');

  // handel rating of product
  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  const { data, error, isLoading } = useQuery({
    queryKey: ["Details"],
    queryFn: Details
  });
  const [Liked, setLiked] = useState(false);

  //  like product and save in wishlisht
const isLiked = () => {
  setLiked(!Liked);
  fetch(`${BASE_URL}/savewishlist`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_id: uid,
      post_id: id
    })
    }).then((res) => {
      return res.text();
    }).then((data) => {
    }).catch((err) => {
      console.log(err.message);
    })
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const redirect = () => {
    nav(`/Payment/${id}/${uid}`)
  }
//  Report Product Api Call 
  const ReportSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm({Email,Reason});
    setErrors(newErrors);
    if(Object.keys(newErrors).length <1){
    fetch(`${BASE_URL}/postreport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "post_id": id,
        "email": Email,
        "message":Reason
      })
    }).then((res) => {
      return res.text();
    }).then((data) => {
      if(data){
        success1()
        SetEmail('')
       SetReason('')
      }
    }).catch((err) => {
      console.log(err.message);
    })
  }
  else{
    fail()
    SetEmail('')
    SetReason('')
  }
  }


  const validateForm = (data) => {
    const errors = {};
    if (!data.Reason.trim()) {
      errors.Reason = 'Comment is required';
    } else if (data.Reason.length<5) {
      errors.Reason = 'The Comment must be at least 5 characters.';
    }
    if (!data.Email.trim()) {
      errors.Email = 'Email is required';
    } else if (data.Email.length<5) {
      errors.Email = 'The Email must be at least 5 characters.';
    }
    return errors;
  };
  // check payment status 
const checkpayment = () => {
    fetch(`${BASE_URL}/checkdeductstatus`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: uid,
        post_id: id,
        type: "1"
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      console.log(data.message)
      if ((data.message).trim()== 'Deducted') {
        Setshowdetails(true)
      }
      else {
        redirect()
      }
    }).catch((err) => {
      console.log(err.message);
    })
  }

   const success=(message)=>{
          Swal.fire({
            title: 'Rating Saved  successfully',
            icon: "success"
          });
        }
        const success1=(message)=>{
          Swal.fire({
            title: 'Your report has been submitted',
            text:"we'll review your report",
            icon: "success"
          });
        }
        const fail =()=>{
          Swal.fire({
            icon: "error",
            title: "Something went wrong!...",
            text: "please fill all details",
          });
        }
  // save rating api
const onSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm1({rating});
    setErrors1(newErrors);
    if(Object.keys(newErrors).length <1){
    fetch(`${BASE_URL}/save_rating`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "user_id": uid,
        "post_id": id,
        "comments": comments,
        "rating": rating
      })
    }).then((res) => {
      return res.text();
    }).then((data) => {
      if(data){
        success()
      }
    }).catch((err) => {
      console.log(err.message);
    })
  }
  }
  const validateForm1 = (data) => {
    const errors = {};
    if (data.rating===0) {
      errors.rating = 'Rating  is required';
    } 
    return errors;
  };
const imgarray = data.result.post.images.split(",");
const imgurl=`${imageUrl1}/${imgarray[0]}`
//  change date formate
const datefromate=(olddate)=>{
    const date = new Date(data.result.post.created_at);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
return (
    <>
      {data && <div className="body_area grid_main">
        <div className="left_a">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
{imgarray&&imgarray.map((ele, i) => {             
 return (
  <SwiperSlide>
  <div class="slider_imags">
    <div class="featured feature_slide">
      <p class="ft_14 roboto_medium">Featured</p>
    </div>
    <img src={`${imageUrl1}/${imgarray[i]}`} alt="iphone" />
  </div>
</SwiperSlide>
)
})
} 
</Swiper>
 <div className="prodct_deatils">
            <div className="d_flex mt_10">
              <p className="ft_18 roboto_medium d_blue"><App htmlEntity= {Currency} /> {data.result.post.price}</p>
              <div className="like_share3">
                <a onClick={handleShare}>
                  <img src={share} alt="share" />
                </a>
                <a onClick={isLiked}>
                  <img src={Liked ? like : inactive} alt="Like" />
                </a>
              </div>
            </div>
            <p className="d_lblack roboto_regular ft_14 mt_10">
              {data.result.post.title}
            </p>
            <div className="d_flex">
              <p className="ft_14 roboto_regular d_elblack mt_10 gap">
                <img src={location} alt="location" />
                {data.result.post.city.name}, {data.result.post.city.country_code}
              </p>
              <p className="ft_14 roboto_regular d_elblack mt_10">{datefromate(data.result.post.created_at)}</p>
            </div>
          </div>

          {/* <p className="ft_18 robot_medium d_black mt_20">Details</p>
          <div className="grid_items mt_10">
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Brand</p>
              <p className="ft_14 roboto_medium d_black">Iphone</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Model</p>
              <p className="ft_14 roboto_medium d_black">13 Pro</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">RAM Size</p>
              <p className="ft_14 roboto_medium d_black">8 GB</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Storage Capacity</p>
              <p className="ft_14 roboto_medium d_black">128 GB</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Physical Condition</p>
              <p className="ft_14 roboto_medium d_black">Superb | No Damage</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Network</p>
              <p className="ft_14 roboto_medium d_black">5G</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Box with IMEI</p>
              <p className="ft_14 roboto_medium d_black">Not Available</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Charger</p>
              <p className="ft_14 roboto_medium d_black">Not Available</p>
            </div>
            <div className="g_item">
              <p className="ft_14 roboto_regular d_lblack">Original Receipt</p>
              <p className="ft_14 roboto_medium d_black">Available</p>
            </div>
          </div> */}
          <p className="ft_18 robot_medium d_black mt_20">Description</p>
          <p className="ft_14 robot_regular d_lblack mt_10">
            {data.result.post.description.replace(/<(.|\n)*?>/g, '')}
          </p>
          <p className="ft_18 robot_medium d_black mt_20">Location</p>
          <iframe
            className="mt_10 map"
            // src={getMapSrc('Noida')}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8070639.283284887!2d3.3776973065334874!3d8.995886348042317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0baf7da48d0d%3A0x99a8fe4168c50bc8!2sNigeria!5e0!3m2!1sen!2sin!4v1733380867449!5m2!1sen!2sin"
            width="100%"
            height={250}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
          <div className="d_flex">
            <p className="ft_14 roboto_regular d_lblack mt_10 gap">
              AD ID:     {data.result.post.id}

            </p>
            <p className="ft_14 roboto_regular d_lblack mt_10 gap d_flex">
              <img src={view} alt="views" /> {data.result.post.view} Views
            </p>
          </div>
          <p className="ft_18 robot_medium d_black mt_20">Address</p>
          <p className="ft_14 robot_regular d_lblack mt_10">{data.result.post.city.name}</p>
          <div className="btm_fixed cont_user mt_10 d_flex ">
            {showdetails && 
              <div className="d_flex gap details_paid">
              <img src={user} alt="user" />
              <div className="">
                <p className="ft_14 robot_medium d_black">{data.result.post.user.name} </p>             
         {     data.result.post.user.mobile_number&&      <p className="ft_14 roboto_regular d_blue gap mt_5">
                  <img src={phone} alt="views" className='ic_sm phone'/>
                  <a href={`tel:${data.result.post.phone}`}>{data.result.post.phone}</a>
                </p> }

              {  data.result.post.user.email&& <p className="ft_14 roboto_regular d_blue gap mt_5">
                  <img src={email} alt="views" className='ic_sm email'/>
                  <a href={`mailto:${data.result.post.user.email}`}>{data.result.post.user.email}</a>
                  
                </p> 
                }
                 {data.result.post.city.name&& <p className="ft_14 roboto_regular d_blue gap mt_5">
                  <img src={location} alt="views" className='ic_sm email'/>
                  <a >{data.result.post.location},</a>&nbsp;<a >{data.result.post.city.name},&nbsp;{data.result.post.country.name}</a>
                  
                </p> 
                }
              </div>
            </div>}
           {!(showdetails)&&(parseInt(data.result.post.user.id) !=parseInt(uid))&&<button className="btn_full  pr_btn roboto_regular waves-effect ft_14" onClick={() => checkpayment()}>
              <img src={chat} alt="Login" />
              Contact Advertiser
            </button>}
          </div>
          <div className=' mt_20 '>
            <p className="ft_18 robot_medium d_black ">Average rating</p>
            <div className='star_rating star_bg  mt_10' style={{ display: "flex", gap: "5px", cursor: "pointer" }}>
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  // onClick={() => handleStarClick(index)}
                  style={{
                    fontSize: "24px",
                    color: "#FFD700" 
                  }}
                >
                  ★
                </span>
              ))}
            </div>

            <p className='ft_14 robot_regular d_elblack mt_10'>0 average based on {data.result.reviews} Reviews</p>
          </div>
          <p className="ft_18 robot_medium d_black mt_20">How would you rate it?</p>
          <div className='star_rating star_bg  mt_10' style={{ display: "flex", gap: "5px", cursor: "pointer" }}>
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                onClick={() => handleStarClick(index)}
                style={{

                  color: index < rating ? "#FFD700" : "#ccc",
                }}
              >
                ★
              </span>
            ))}
            <p className="ft_14 robot_regular d_elblack " style={{ marginLeft: "10px" }}>You rated: {rating} star(s)</p>
          </div>
          {errors1.rating && (
                <span className="error-message">
                  {errors1.rating}
                </span>
              )}
          <form >
            {/* <textarea className='review_area mt_20 ft_14' placeholder='write a review here...' value={comments}
              onChange={(e) => setcomments(e.target.value)}
            ></textarea> */}
            <button class="pr_btn roboto_regular  ft_14 mt_10" onClick={onSubmit}>Submit</button>
          </form>
          <p className="ft_18 robot_medium d_black mt_20">More Info</p>
          {/* <Link to={'/Profile'} class="waves-effect location_btn roboto_regular ft_14 bnt_brdr mt_10">More Listings of {data.result.post.user.name} </Link> */}
          <a className="location_btn roboto_regular ft_14 bnt_brdr mt_10 modal-trigger"  data-target="modal1">Report this ad</a>
         <div className="cont_user mt_10 d_flex fixed_btm">
            <div className="d_flex gap">
              <img src={user} alt="user" />
              <div className="">
                <p className="ft_18 robot_medium d_black mt_10">{data.result.post.user.username} </p>
              </div>
            </div>
            <button className="pr_btn roboto_regular waves-effect ft_14">
              <img src={chat} alt="Login" />
              Contact now
            </button>
          </div>
        </div>
        {/* <div className="right_a">
          <p className="ft_18 robot_medium d_black mt_10">Related</p>
          <div className="r_card mt_10">
            <RelatedProduct></RelatedProduct>
          </div>
        </div> */}
<div id="modal1" class="modal bottom-sheet">
  <div class="modal-content">
  <p class="ft_18 robot_medium d_black ">Report an ad</p>
  <form>
    <div class="input-field col s12">
      {/* <p class="ft_14 roboto_medium d_black mt_10">Email<span class="redd">*</span></p> */}
      <input placeholder="Enter your email..." id="Email" type="email" class="ft_14 validate" name="Email" value={Email} onChange={(e) => SetEmail(e.target.value)}  validate/>
      <textarea className='review_area  ft_14' placeholder='write a comment...' name='Reason'value={Reason}  onChange={(e) => SetReason(e.target.value)} validate></textarea>
      {errors.Reason && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}
    </div>
    <a className="modal-close btn_full pr_btn roboto_regular ft_14" onClick={ReportSubmit}>Submit</a>  
  </form>
  </div>
</div>
      </div>
      }
    </>
  )
}

export default ProductDetails
