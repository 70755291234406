import React from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import M from "materialize-css/dist/js/materialize.min.js";
import { useEffect, useState } from 'react'
import Camera from '../../../images/camera.svg'
import Add from '../../../images/add.svg'
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetCountry } from '../../../Apis/GetCountry'
import verified from '../../../images/verified.png'
import { imageUrl } from '../../../Apis/config';
const cookies = new Cookies();
const EditProduct = (props) => {
    const nav = useNavigate()
    const {pid,uid}=useParams()
      const success=(message)=>{
        Swal.fire({
          title:message,
          text: "Please wait for 24 hours to approve your item",
          icon: "success"
        });
      }
      const Categories = cookies.get('Categories');
      const id = cookies.get('Id');
      console.log('Cat',Categories)
    //   setimage value
       const [singleFile, setSingleFile] = useState([]);
        const [imageurl, setimageurl] = useState([]);
        const [imageurl2, setimageurl2] = useState([]); //
        const [errors, setErrors] = useState({});
          const [CatVal, SetCatVal] = useState(null)
            const [Cat, SetCat] = useState(false)
    
    // upload image
   const uploadSingleFiles = async (e) => {
       const file = e.target.files[0];
       if (file) {
         const fileUrl = URL.createObjectURL(file); // Create a preview URL
         setSingleFile((prevFiles) => [...prevFiles, fileUrl]); // Add preview to the state
         const formData = new FormData();
         formData.append("image[0]", file);
         try {
           const response = await axios.post(`${BASE_URL}/image_upload`, formData, {
             headers: { "Content-Type": "multipart/form-data" },
           });
           // console.log("Image uploaded successfully:", response.data);
           setimageurl((prevFiles) => [...prevFiles, response.data.image_name]);
         } catch (error) {
           console.error("Error uploading image:", error);
         }
       }
     };
    //  Remove image
   const removeImage = (index) => {
    console.log("reomve");
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length)
    ]);
    setimageurl([...imageurl.slice(0, index),
    ...imageurl.slice(index + 1, imageurl.length)])
  };
   const handleUploadClick = () => {
    document.getElementById("fileInput").click(); // Simulate file input click
  };
//  const onSubmit = (e) => {
//     e.preventDefault();
//     // console.log(formData)
//     fetch(`${BASE_URL}/updatepost`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//             post_id:props.data.result.post.id,
//             category: category,
//             sub_category: sub_category,
//             title: title,
//             description:description,
//             image:imageurl,
//             price:price,
//             phone: phone,
//             city:city,
//             location:location,
//             latitude:latitude,
//             longitude:longitude,
//             tags: tags,
//             make_premium:make_premium,
//             agree_for_term:agree_for_term,
//            negotiable:negotiable
//         })
//     }).then((res)=>{
        
//         return res.text();
//     }).then((data)=>{
//         success('Item Posted Successfully')
//         console.log('jhfjf',data);  
//     }).catch((err)=>{
//         console.log(err.message);   
//     })

// }
useEffect(() => {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {});
  });
// Set form details
  const [email, Setemail] = useState('')
  const [name, Setname] = useState('')
  const [mobile, Setmobile] = useState('')
  const [email1, Setemail1] = useState('')
  const [emailstatus, SetEmailstatus] = useState(null)
    const [user_id, Setuser_id] = useState(id)
    const [category, Setcategory] = useState('')
    const [sub_category, Setsub_category] = useState('')
    const [title, Settitle] = useState('')
    const [description, Setdescription] = useState('')
    const [image, Setimage] = useState([])
    const [price, Setprice] = useState('')
    const [phone, Setphone] = useState('')
    const [city, Setcity] = useState('')
    const [location, Setlocation] = useState('')
    const [latitude, Setlatitude] = useState('36.6000')
    const [longitude, Setlongitude] = useState('-121.89000')
    const [tags, Settags] = useState('')
    const [make_premium, Setmake_premium] = useState('0')
    const [agree_for_term, Setagree_for_term] = useState('')
    const [negotiable, Setnegotiable] = useState('0')

// Set address value
const [state, setstate] = useState([]);
const [CityData, SetCityData] = useState("")
const [Country, SetCountry] = useState([]);
const [selectedstate, setSelectedstate] = useState("");
const [SelectedCountry, SetSelectedCountry] = useState("");
const [SelectedCountryId, SetSelectedCountryId] = useState("")
const [SelectedCity, SetSelectedCity] = useState('')
const [SelectedCityId, SetSelectedCityId] = useState("")
useEffect(() => {
    fetch(`${BASE_URL}/posts/${pid}/edit?user_id=${id}`, {
    }).then((res)=>{
        return res.json();
    }).then((data)=>{
        Setemail(data.result.post.emailed)
        Setcategory(data.result.post.category.name)
        Setsub_category(data.result.post.sub_category.name)
        Settitle(data.result.post.title)
        Setdescription(data.result.post.description)
        setimageurl2(data.result.post.images)
        Setprice(data.result.post.images)
        SetSelectedCity(data.result.post.city.name)
        SetSelectedCountry(data.result.post.country.name)
        setSelectedstate(data.result.post.country.name)
        Setlocation(data.result.post.location)
        Settags(data.result.post.tag)
        Setphone(data.result.post.phone)
        Setprice(data.result.post.price)
    }).catch((err)=>{
        console.log(err.message);   
    })
  },[]);
//   ... split image url
//    const imgarray = !(imageurl2)?'':imageurl2.split(",");
//     const imgurl=`${imageUrl}/${imgarray}`
//     console.log(imageurl2)
// submit form
const redirect = (type) => {
    nav(`/PostPayment/${pid}/${id}/${type}`)
  }
  const onSubmit1 = (e) => {
      e.preventDefault();
    //   const newErrors = validateForm({ category, title, description, singleFile, price, phone, SelectedCity, location, tags, make_premium, agree_for_term, email });
      setErrors('');
        fetch(`${BASE_URL}/updatepost`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
     post_id: pid,
     category:category,
     sub_category:sub_category, 
     title:title,
    description:description,
     image:imageurl,
     price:price,
     phone:phone,
    "city" : "1466",
    location:location,
    latitude:latitude,
    longitude:longitude,
    tags:tags,
    make_premium:make_premium,
    negotiable:negotiable
          })
        }).then((res) => {
          return res.json();
        }).then((data) => {
          if (data.status == 'success') {
            redirect(make_premium)
          }
          console.log('jhfjf', 
          data);
        }).catch((err) => {
          console.log(err.message);
        })
      
    }

// Validation form
//   const validateForm = (data) => {
//     const errors = {};
//     if (!data.category.trim()) {
//       errors.category = 'category is required';
//     }
//     if (!data.title.trim()) {
//       errors.title = 'title is required';
//     } else if (data.title.length < 5) {
//       errors.title = 'The title must be at least 5 characters.';
//     }
//     if (!data.description.trim()) {
//       errors.description = 'description is required';
//     } else if (data.description.length < 4) {
//       errors.description = 'description must be at least 4 characters long';
//     }

//     if (!data.price.trim()) {
//       errors.price = 'Price is required';
//     } else if (data.price.length < 4) {
//       errors.price = 'Price must be at least 4 characters long';
//     }
//     if (!data.phone.trim()) {
//       errors.phone = 'phone is required';
//     } else if (data.phone.length < 4) {
//       errors.phone = 'phone must be at least 4 characters long';
//     }
//     if (!data.SelectedCity.trim()) {
//       errors.SelectedCity = 'city is required';
//     }
//     if (!data.location.trim()) {
//       errors.location = 'location is required';
//     } else if (data.location.length < 4) {
//       errors.location = 'location must be at least 4 characters long';
//     }
//     if (!data.tags.trim()) {
//       errors.tags = 'title is required';
//     } else if (data.tags.length < 4) {
//       errors.tags = 'title must be at least 4 characters long';
//     }
//     if (!data.make_premium.trim()) {
//       errors.make_premium = 'Listing is required';
//     }
//     if (!data.singleFile) {
//       errors.singleFile = 'Image is required';
//     }
//     else if (data.singleFile.length < 2) {
//       errors.singleFile = ' 2Image is required';
//     }
//     if (!data.email.trim()) {
//       errors.email = 'Email is required';
//     }
//     return errors;
//   };
//  Handel location changes
  const HandleCiity = (e) => {
    SetSelectedCity(e.target.value);
  };
  const HandelCountry = (e) => {
    SetSelectedCountryId(e.target.value);
  };
  const HandelState = (e) => {
    setSelectedstate(e.target.value);
  };

  
    // Get Country
    useEffect(() => {
      ; (async () => {
        try {
          const endPoint = 'allcountry'
          const Res = await GetCountry(BASE_URL, endPoint);
          SetCountry(Res.result)
        }
        catch (error) {
          console.log(error)
        }
      })()
    }, []);
    // Get state
    useEffect(() => {
      fetch(`${BASE_URL}/getstate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          country_code: SelectedCountryId
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setstate(data.result)
      }).catch((err) => {
        console.log(err.message);
      })
    }, [SelectedCountryId]);
    // Get City
    useEffect(() => {
      fetch(`${BASE_URL}/citybystateid`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          state_code: selectedstate
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        SetCityData(data)
      }).catch((err) => {
        console.log(err.message);
      })
    }, [selectedstate]);


    const handleCategoryChange = (e) => {
        Setcategory(e.target.value);
        fetch(`${BASE_URL}/subcategorybyid`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            category_id: e.target.value,
          })
        }).then((res) => {
          return res.json();
        }).then((data) => {
          SetCatVal(data)
        }).catch((err) => {
          console.log(err.message);
        })
        SetCat(true); // Reset subcategory when category changes
      };
       useEffect(() => {
          const elems = document.querySelectorAll('select');
          M.FormSelect.init(elems, {});
        });


        console.log('sds',category)
  return (
    <> <div  className="body_area grid_main">
            <div className='left_a'>
                    <p className='ft_18 roboto_medium d_black mt_20'>Edit Product details</p>
                    <form >

    {/* Commented Data  Select  Categories */}


            {/* <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Categories<span class="redd">*</span></p>
              <select name='category' value={category} onChange={handleCategoryChange}>
                <option value="" disabled selected>Select Categories</option>
                {Categories && Categories.map((option) => (
                  <option key={option.id} value={option.id} data-icon={option.picture}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}

            </div>

            {category &&
              <div class="input-field col s12 ">
                <select name='sub_category' value={sub_category} onChange={(e) => Setsub_category(e.target.value)} >
                  <option value>Select Sub Category</option>
                  {CatVal && CatVal.map((subcat, index) => {
                    // console.log("SAJID : ",index,subcat);
                    return (
                      <>
                        <option value={subcat.id} >{subcat.name}</option>
                      </>
                    )
                  })}
                </select>
              </div>
            } */}



            {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}


            {/* Commented Data Title Description */}

            {/* <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Title<span class="redd">*</span></p>
              <input placeholder="Enter title for your listing..." id="first_name" type="text" class="validate" name="title" value={title} onChange={(e) => Settitle(e.target.value)} disabled />
              {errors.title && (
                <span className="error-message">
                  {errors.title}
                </span>
              )}
            </div>

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Description<span class="redd">*</span></p>
              <textarea class="review_area mt_20 ft_14" placeholder="Include condition, features and reason for selling..." name='description' value={description} onChange={(e) => Setdescription(e.target.value)} disabled></textarea>
              {errors.description && (
                <span className="error-message">
                  {errors.description}
                </span>
              )}
            </div> */}


            <p className='ft_14 roboto_medium d_black mt_10'>Upload pictures upto 10<span class="redd">*</span></p>
            <div class="img_uplo mt_10">
              {singleFile.length !== 0 &&
                singleFile.map((url, index) => (
                  <>
                    <div class="circle22 p_image" key={url}>
                      <img class="profile-pic" src={url} onClick={handleUploadClick}></img>
                      <span
                        className="remove_img"
                        onClick={() => removeImage(index)}>
                        X
                      </span>
                    </div>
                  </>
                ))}
              {singleFile.length >9? null : (
                <>
                  <div class="p_image" onClick={handleUploadClick}>
                    <img class="fa fa-camera upload-button" src={Camera} ></img>
                    <input class="file-upload" type="file" accept="image/*" id="fileInput" onChange={uploadSingleFiles}  />
                    <p className='ft_14 d_black roboto_medium'>Add Photo</p>
                  </div>
                </>
              )}
              {errors.singleFile && (
                <span className="error-message">
                  {errors.singleFile}
                </span>
              )}
            </div>

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Price <span class="redd">*</span></p>
              <input placeholder="Set a price" id="first_name" type="number" class="validate" name='price' value={price} onChange={(e) => Setprice(e.target.value)} />
              {errors.price && (
                <span className="error-message">
                  {errors.price}
                </span>
              )}
            </div>
  
  
  {/* Commented Data check and phone number and email  and address*/}


            {/* <label>
              <input type="checkbox" />
              <span name='negotiable' onClick={() => Setnegotiable(1)}>Negotiable</span>
            </label>
            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Phone Number<span class="redd">*</span></p>
              <input id="icon_telephone" type="number" class="validate" name='phone' value={phone} onChange={(e) => Setphone(e.target.value)} placeholder='Enter your Mobile Number...' disabled />
              {errors.phone && (
                <span className="error-message">
                  {errors.phone}
                </span>
              )}
            </div>
            {email&&<div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Email<span class="redd">*</span></p>
             <div>
                <div className='send_otp_btn'>
                  <input id="icon_telephone" type="email" class="validate" name='email' value={email} onChange={(e) => Setemail(e.target.value)} disabled />
                  {parseInt(emailstatus) == 1 && <a className='pr_btn roboto_regular ft_14 verified'><img src={verified} alt=" " class="btn_img" />Verified</a>
                  }
                 
                </div>
              </div>
              
            </div>}
            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Country<span class="redd">*</span></p>
              <select name='category' value={SelectedCountryId} onChange={HandelCountry}disabled >
                <option value="" >Select Country</option>
                {Country && Country.map((option) => (
                  <option key={option.id} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}
            </div>
            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>State<span class="redd">*</span></p>
              <select name='category' value={selectedstate} onChange={HandelState} disabled >
                <option value="" disabled selected>Select State</option>
                {state && state.map((option) => (
                  <option key={option.id} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}

            </div>

            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>City<span class="redd">*</span></p>
              <select name='city' value={SelectedCity} onChange={HandleCiity} disabled >
                <option value="" selected disabled >Select City</option>
                {CityData && CityData.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.city && (
                <span className="error-message">
                  {errors.city}
                </span>
              )}
            </div>

            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Address <span class="redd">*</span></p>
              <input id="icon_telephone" type="text" class="validate" name='location' value={location} onChange={(e) => Setlocation(e.target.value)} disabled />
              {errors.location && (
                <span className="error-message">
                  {errors.location}
                </span>
              )}
            </div> */}

            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Tags<span class="redd">*</span></p>
              <input id="icon_telephone" type="text" class="validate" name='tags' value={tags} onChange={(e) => Settags(e.target.value)} />
              {errors.tags && (
                <span className="error-message">
                  {errors.tags}
                </span>
              )}
            </div>
            {/* <!--premium --> */}
            <p className='ft_14 roboto_medium d_black mt_10'>Select Plan<span class="redd">*</span></p>
            <div className='d_flex l_gap mt_10'>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="0"
                    checked={make_premium === "0"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Normal Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Normal Charges: 0.1 Usd</p>
              </div>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="1" checked={make_premium === "1"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Premium Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Premium  Charges: 0.2 Usd</p>
              </div>
              {errors.make_premium && (
                <span className="error-message">
                  {errors.make_premium}
                </span>
              )}
            </div>
            <div class="mt_20">
              <label>
                <input type="checkbox" />
                <span name='agree_for_term' onClick={() => Setagree_for_term(1)}>I have read and agree to the Terms & Conditions</span>
              </label>
              {errors.agree_for_term && (
                <span className="error-message">
                  {errors.agree_for_term}
                </span>
              )}
            </div>
            <button class="mt_20 btn_full pr_btn roboto_regular waves-effect ft_14 mt_10" onClick={onSubmit1}>
              <img src={Add} alt=" " className='btn_img' />
              Post an AD
            </button>
          </form>      
            </div>
            <div className='right_a'></div>
      </div>
    </>
  )
}
export default EditProduct
