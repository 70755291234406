import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import { GetCountry } from '../../../Apis/GetCountry'
import axios from 'axios';
import M from "materialize-css/dist/js/materialize.min.js";
import Camera from '../../../images/camera.svg'
import Add from '../../../images/add.svg'
import Swal from 'sweetalert2';
import { Form, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import verified from '../../../images/verified.png'
const cookies = new Cookies();
const AddProduct = (props) => {
  // get data from cookies 
  const CountryCode =!(cookies.get('Country'))?"NG":cookies.get('Country');
  const StateName =cookies.get('Statename');
  const StateCode =cookies.get('Statevalue');
  const CountryName = !(cookies.get('CountryName'))?"Nigeria":cookies.get('CountryName');
  const CurrencyCode =!(cookies.get('Currencycode'))?"NGN":cookies.get('Currencycode');
  const CityCode =cookies.get('CityId');
  const CityName =cookies.get('CityName');
  const [showComponent, setShowComponent] = useState(false);
  const inputs = ["input1", "input2", "input3", "input4", "input5", "input6"];
  const [inputs1, setInputs1] = useState({ input1: "", input2: "", input3: "", input4: "" });
  // Add listeners to each input
  inputs.forEach((id) => {
    const input = document.getElementById(id);
    if (input) {
      addListener(input);
    }
  });
const CountryList=[
  { "country_code": "US", "mobile_no_length": 10 },
  { "country_code": "IN", "mobile_no_length": 10 },
  { "country_code": "GB", "mobile_no_length": 10 },
  { "country_code": "AU", "mobile_no_length": 9 },
  { "country_code": "DE", "mobile_no_length": 10 },
  { "country_code": "FR", "mobile_no_length": 9 },
  { "country_code": "JP", "mobile_no_length": 10 },
  { "country_code": "KR", "mobile_no_length": 9 },
  { "country_code": "CA", "mobile_no_length": 10 },
  { "country_code": "BR", "mobile_no_length": 11 },
  { "country_code": "RU", "mobile_no_length": 10 },
  { "country_code": "CN", "mobile_no_length": 11 },
  { "country_code": "ZA", "mobile_no_length": 9 },
  { "country_code": "MX", "mobile_no_length": 10 },
  { "country_code": "IT", "mobile_no_length": 10 },
  { "country_code": "ES", "mobile_no_length": 9 },
  { "country_code": "SA", "mobile_no_length": 9 },
  { "country_code": "AR", "mobile_no_length": 10 },
  { "country_code": "NG", "mobile_no_length": 10 }
]

function GetCountryLength(countryCode) {
  const country = CountryList.find(item => item.country_code === countryCode);
  return country ? country.mobile_no_length : 'Country code not found';
}
  function addListener(input) {
    input.addEventListener("keyup", (event) => {
      const { key, target } = event; // Destructure key and target from event
      const code = parseInt(target.value, 10);
      if (code >= 0 && code <= 9) {
        const next = input.nextElementSibling;
        if (next) next.focus();
      } else {
        target.value = ""; 
      }
      if (key === "Backspace" || key === "Delete") {
        const prev = input.previousElementSibling;
        if (prev) prev.focus();
      }
    });
  }
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputs1((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  

  const id = cookies.get('Id');
  const Categories = cookies.get('Categories');
  const nav = useNavigate()
  const queryClient = useQueryClient();

  const redirect = (pid, type) => {
    nav(`/PostPayment/${pid}/${id}/${type}`)
  }
  const [singleFile, setSingleFile] = useState([]);
  const [imageurl, setimageurl] = useState([]);
  const [profilePic, setProfilePic] = useState(null); //
  const [errors, setErrors] = useState({});

  // uploda image and save in api

const uploadSingleFiles = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file); // Create a preview URL
      setSingleFile((prevFiles) => [...prevFiles, fileUrl]); // Add preview to the state
      const formData = new FormData();
      formData.append("image[0]", file);
      try {
        const response = await axios.post(`${BASE_URL}/image_upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setimageurl((prevFiles) => [...prevFiles, response.data.image_name]);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  const uploadFiles = (e) => {
    e.preventDefault();
    console.log(singleFile);
  };
  // remove  selected image

  const removeImage = (index) => {
    console.log("reomve");
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length)
    ]);
    setimageurl([...imageurl.slice(0, index),
    ...imageurl.slice(index + 1, imageurl.length)])
  };
  const handleUploadClick = () => {
    document.getElementById("fileInput").click(); // Simulate file input click
  };


  // update email details
  const [email, Setemail] = useState('')
  const [name, Setname] = useState('')
  const [mobile, Setmobile] = useState('')
  const [email1, Setemail1] = useState('')
  const [emailstatus, SetEmailstatus] = useState(null)
  const [emailVerify, SetEmailVerify] = useState(null)
  const [Opt_sent, SetOpt_sent] = useState('')
  const [Verifed, SetVerifed] = useState('')
  const [otp, Setotp] = useState('')
  const [Cat, SetCat] = useState(false)
  const [CatVal, SetCatVal] = useState(null)

  // Get user profile  api

useEffect(() => {
    fetch(`${BASE_URL}/getprofile`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: id,
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      Setemail(data.result[0].email)
      Setname(data.result[0].name)
      Setmobile(data.result[0].mobile_number)
      SetEmailstatus(data.result[0].is_email_varify)
    }).catch((err) => {
      console.log(err.message);
    })
  }, [emailVerify]);

  // from data state

  const [user_id, Setuser_id] = useState(id)
  const [category, Setcategory] = useState('')
  const [sub_category, Setsub_category] = useState('')
  const [title, Settitle] = useState('')
  const [description, Setdescription] = useState('')
  const [image, Setimage] = useState([])
  const [price, Setprice] = useState('')
  const [phone, Setphone] = useState('')
  const [city, Setcity] = useState('')
  const [location, Setlocation] = useState('')
  const [latitude, Setlatitude] = useState('36.6000')
  const [longitude, Setlongitude] = useState('-121.89000')
  const [tags, Settags] = useState('')
  const [make_premium, Setmake_premium] = useState('0')
  const [agree_for_term, Setagree_for_term] = useState('')
  const [negotiable, Setnegotiable] = useState('0')

  const UserDetails = {
    user_id: id,
    name: name,
    email: email1,
    mobile_number: mobile
  }

  const Updateemail = async (e) => {
    try {
      const response = await axios.post(`${BASE_URL}/updateprofile`, UserDetails, {
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const success = (message) => {
    Swal.fire({
      title: message,
      text: "Please wait for 24 hours to approve your item",
      icon: "success"
    });
  }
  // submit form data to api 


  const Confirmation = () => {
      Swal.fire({
        title: "You cannot modify product details after submission.",
        text:"Please check before submit",
        showDenyButton: true,
        confirmButtonText: "Submit",
        denyButtonText: `Check`,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit();
        }
      });
    }

    const SubmitDetails=(e)=>{
      e.preventDefault();
      const newErrors = validateForm({ category, title, description, singleFile, price, phone, SelectedCity, location, make_premium, agree_for_term, negotiable, email1 });
      setErrors(newErrors);
      if (Object.keys(newErrors).length <= 1 && emailstatus == 1) {
        Confirmation();
      }
    }
  const onSubmit = () => {
    if (!email) {
      Updateemail()
    }
      fetch(`${BASE_URL}/insertpost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user_id,
          category: category,
          sub_category: sub_category,
          title: title,
          description: description,
          image: imageurl,
          price: price,
          phone: phone,
          city: SelectedCity,
          location: location,
          latitude: latitude,
          longitude: longitude,
          tags: tags,
          make_premium: make_premium,
          agree_for_term: agree_for_term,
          negotiable: negotiable,
          featured: make_premium == 0 ? "0" : "1",
          urgent: make_premium == 0 ? "0" : "1",
          highlight: make_premium == "0" ? "0" : "1",
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        if (data.status == 'success') {
          redirect(data.post_id, make_premium)
        }
      }).catch((err) => {
        console.log(err.message);
      })
  }
  // form validation 

  const validateForm = (data) => {
    const errors = {};
    if (!data.category.trim()) {
      errors.category = 'category is required';
    }
    if (!data.title.trim()) {
      errors.title = 'title is required';
    } else if (data.title.length < 5) {
      errors.title = 'The title must be at least 5 characters.';
    }
    if (!data.description.trim()) {
      errors.description = 'description is required';
    } else if (data.description.length < 4) {
      errors.description = 'description must be at least 4 characters long';
    }

    if (!data.price.trim()) {
      errors.price = 'Price is required';

    } else if (data.price.length <1|| data.price.length >15) {
      errors.price = 'Price must be 1 to 15 digit ';
    }
    else if (data.price<=0) {
      errors.price = 'Price can not be zero or negative ';
    }
    if (!data.phone.trim()) {
      errors.phone = 'phone is required';
    } else if (data.phone.length <GetCountryLength(SelectedCountryId)||data.phone.length >GetCountryLength(SelectedCountryId)) {
      errors.phone = `phone number bitween 1 to ${GetCountryLength(SelectedCountryId)}  digit long`;
    }
    // if (!data.SelectedCity.trim()) {
    //   errors.SelectedCity = 'city is required';
    // }
    if (!data.location.trim()) {
      errors.location = 'location is required';
    } else if (data.location.length < 4) {
      errors.location = 'location must be at least 4 characters long';
    }
    
    if (!data.make_premium.trim()) {
      errors.make_premium = 'Listing is required';
    }
    if (!data.singleFile) {
      errors.singleFile = 'Image is required';
    }
    else if (data.singleFile.length < 2) {
      errors.singleFile = 'Two images are required';
    }
    if (!data.email1.trim()) {
      errors.email1 = 'Email is required';
    }
    else if (data.email1.indexOf(" ") !== -1) {
      errors.email1 = 'Space not Allowed';
    }
    return errors;
  };

  // get sub_category api
  const handleCategoryChange = (e) => {
    Setcategory(e.target.value);
    fetch(`${BASE_URL}/subcategorybyid`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        category_id: e.target.value,
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      SetCatVal(data)
    }).catch((err) => {
      console.log(err.message);
    })
    SetCat(true); 
  };

const HandleCiity = (e) => {
    SetSelectedCity(e.target.value);
  };

const HandelCountry = (e) => {
    SetSelectedCountryId(e.target.value);
    const selectedIndex = e.target.selectedIndex;
    const additionalData = e.target.options[selectedIndex].getAttribute("data-extra");
    SetSelectedCurrency(additionalData)
  };

  const HandelState = (e) => {
    setSelectedstate(e.target.value);
  };

  const SendOtp = () => {
    alert("mail send")
    fetch(`${BASE_URL}/varifyemail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: id,
        email: !(email) ? email1 : email
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      console.log('Data',data.status)
      SetOpt_sent(data.status)
    }).catch((err) => {
      console.log(err.message);
    })
  }
  const response = () => {
    fetch(`${BASE_URL}/getprofile`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: id,
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      Setemail(data.result[0].email)
      Setname(data.result[0].name)
      Setmobile(data.result[0].mobile_number)
      SetEmailstatus(data.result[0].is_email_varify)
    }).catch((err) => {
      console.log(err.message);
    })

  }
  const Otp1 = Object.values(inputs1).join("");
  const VerifyMailFucntion = () => {
    fetch(`${BASE_URL}/varifyotp`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: id,
        otp: Otp1
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      console.log('verifi',data.status)
      SetEmailVerify(data.status)
      if (data.status == 1) {
        SetVerifed(1)
      }
      response()
    }).catch((err) => {
      console.log(err.message);
    })
  }
  // Set address value
  const [state, setstate] = useState([]);
  const [CityData, SetCityData] = useState("")
  const [Country, SetCountry] = useState([]);
  const [selectedstate, setSelectedstate] = useState(StateCode);
  const [SelectedCountry, SetSelectedCountry] = useState("");
  const [SelectedCurrency, SetSelectedCurrency] = useState("");
  const [SelectedCountryId, SetSelectedCountryId] = useState(CountryCode)
  const [SelectedCity, SetSelectedCity] = useState(CityCode)
  const [SelectedCityId, SetSelectedCityId] = useState("")
  // Get Country && currency
  const NewCurrency =!(SelectedCurrency)?CurrencyCode:SelectedCurrency
  useEffect(() => {
    ; (async () => {
      try {
        const endPoint = 'allcountry'
        const Res = await GetCountry(BASE_URL, endPoint);
        SetCountry(Res.result)
      }
      catch (error) {
        console.log(error)
      }
    })()
  }, []);
  // Get state
  useEffect(() => {
    fetch(`${BASE_URL}/getstate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        country_code: SelectedCountryId
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      setstate(data.result)
    }).catch((err) => {
      console.log(err.message);
    })
  }, [SelectedCountryId]);
  // Get City
  useEffect(() => {
    fetch(`${BASE_URL}/citybystateid`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        state_code: selectedstate
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      SetCityData(data)
    }).catch((err) => {
      console.log(err.message);
    })
  }, [selectedstate]);
  useEffect(() => {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {});
  });
  console.log('Citydd',SelectedCity)
  console.log('State',selectedstate)
  console.log('cotyr',SelectedCountryId)
  return (
    <>
      <div className="body_area grid_main">
        <div className='left_a'>
          <p className='ft_18 roboto_medium d_black mt_20'>Post An Advertise</p>
          <form >
            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Categories<span class="redd">*</span></p>
              <select name='category' value={category} onChange={handleCategoryChange} >
                <option value="" disabled selected>Select Categories</option>
                {Categories && Categories.map((option) => (
                  <option key={option.id} value={option.id} data-icon={option.picture}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}

            </div>

            {category &&
              <div className="input-field col s12 ">
                <select name='sub_category' value={sub_category} onChange={(e) => Setsub_category(e.target.value)} >
                  <option value>Select Sub Category</option>
                  {CatVal && CatVal.map((subcat, index) => {
                    // console.log("SAJID : ",index,subcat);
                    return (
                      <>
                        <option value={subcat.id} >{subcat.name}</option>
                      </>
                    )
                  })}
                </select>
              </div>
            }

            {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}

            <div className="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Title<span class="redd">*</span></p>
              <input placeholder="Enter title for your listing..." id="first_name" type="text" class="validate" name="title" value={title} onChange={(e) => Settitle(e.target.value)} />
              {errors.title && (
                <span className="error-message">
                  {errors.title}
                </span>
              )}
            </div>

            <div className="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Description<span class="redd">*</span></p>
              <textarea class="review_area mt_20 ft_14" placeholder="Include condition, features and reason for selling..." name='description' value={description} onChange={(e) => Setdescription(e.target.value)}></textarea>
              {errors.description && (
                <span className="error-message">
                  {errors.description}
                </span>
              )}
            </div>


            <p className='ft_14 roboto_medium d_black mt_10'>Upload pictures upto 10<span class="redd">*</span></p>
            <div className="img_uplo mt_10">
              {singleFile.length !== 0 &&
                singleFile.map((url, index) => (
                  <>
                    <div class="circle22 p_image" key={url}>
                      <img class="profile-pic" src={url} onClick={handleUploadClick}></img>
                      <span
                        className="remove_img"
                        onClick={() => removeImage(index)}>
                        X
                      </span>
                    </div>
                  </>
                ))}
              {singleFile.length > 9 ? null : (
                <>
                  <div className="p_image" onClick={handleUploadClick}>
                    <img className="fa fa-camera upload-button" src={Camera} ></img>
                    <input className="file-upload" type="file" accept="image/*" id="fileInput" onChange={uploadSingleFiles} />
                    <p className='ft_14 d_black roboto_medium'>Add Photo</p>
                  </div>
                </>
              )}
              {errors.singleFile && (
                <span className="error-message">
                  {errors.singleFile}
                </span>
              )}
            </div>

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Price <span class="redd">*</span></p>
              <div className='send_otp_btn'>
                <input placeholder="Set a price" id="first_name" type="number" class="validate" name='price' value={price} onChange={(e) => Setprice(e.target.value)} />
                <a className='pr_btn roboto_regular ft_14 verified'>{NewCurrency}</a>
                {errors.price && (
                  <span className="error-message">
                    {errors.price}
                  </span>
                )}
                </div>
            </div>
            <label>
              <input type="checkbox" />
              <span name='negotiable' onClick={() => Setnegotiable(1)}>Negotiable</span>
            </label>
            <div className="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Phone Number<span class="redd">*</span></p>
              <input id="icon_telephone" type="number" class="validate" name='phone' value={phone} onChange={(e) => Setphone(e.target.value)} placeholder='Enter your Mobile Number...' />
              {errors.phone && (
                <span className="error-message">
                  {errors.phone}
                </span>
              )}
            </div>
            <div className="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Email<span class="redd">*</span></p>
              {email && parseInt(emailstatus) == 1&&<div>
                <div className='send_otp_btn'>
                  <input id="icon_telephone" type="email" class="validate" name='email' value={email} onChange={(e) => Setemail(e.target.value)} disabled />
                  {parseInt(emailstatus) == 1 && <a className='pr_btn roboto_regular ft_14 verified'><img src={verified} alt=" " class="btn_img" />Verified</a>
                  }
                </div>
              </div>
              }
              {email && parseInt(emailstatus) != 1&&<div>
                <div className='send_otp_btn'>
                  <input id="icon_telephone" type="email" class="validate" name='email' value={email} onChange={(e) => Setemail(e.target.value)} />
                  {((emailstatus == 0 && Opt_sent != 1)|| emailVerify == 0) &&  <a className='pr_btn roboto_regular ft_14 ' onClick={SendOtp} >Send OTP</a>}
                  {Opt_sent == 1 &&emailVerify != 0&& <a className='pr_btn roboto_regular ft_14  otp_sent' >OTP Sent</a>}
                </div>
              </div>
              }
              {!email && <div className='send_otp_btn'>
                <input id="icon_telephone" type="email" class="validate" name='email1' value={email1} onChange={(e) => Setemail1(e.target.value)} required placeholder='Enter your email...' />
                {/* {parseInt(emailstatus)==1&&<a className='pr_btn roboto_regular ft_14 verified'><img src={verified} alt=" " class="btn_img" />Verified</a>
              }    */}
                {errors.email1 && (
                  <span className="error-message">
                    {errors.email1}
                  </span>
                )}
                {emailstatus == 0 && Opt_sent != 1&& <a className='pr_btn roboto_regular ft_14 ' onClick={SendOtp} >Send OTP</a>}
                {Opt_sent == 1 && emailVerify != 0&& <a className='pr_btn roboto_regular ft_14  otp_sent' >OTP Sent</a>}
              </div>
              }
              {Opt_sent == 1 && emailstatus == 0&&   
                <div className=''>
                  <p class="ft_14 roboto_medium d_black mt_10">Enter OTP</p>
                  <p class="ft_10 roboto_regular d_lblack mt_10">We have sent a verification code to your Email ID</p>
                  <div id="inputs" class="mt_10">
                    <input id="input1" type="number" maxlength="1" value={inputs.input1} onChange={handleChange} />
                    <input id="input2" type="number" maxlength="1" value={inputs.input2}
                      onChange={handleChange} />
                    <input id="input3" type="number" maxlength="1" value={inputs.input3}
                      onChange={handleChange} />
                    <input id="input4" type="number" maxlength="1" value={inputs.input4}
                      onChange={handleChange} />
                    <input id="input5" type="number" maxlength="1" value={inputs.input5}
                      onChange={handleChange} />
                    <input id="input6" type="number" maxlength="1" value={inputs.input6}
                      onChange={handleChange} />
                  </div>
                  {emailVerify == 0 && <p className="ft_10 roboto_regular d_lblack ">Enter Valid OTP</p>}
                  {emailVerify == 1 && <p className="ft_10 roboto_regular d_lblack ">verified successfully</p>}
{     emailVerify != 1&&             <a className='btn_full pr_btn roboto_regular waves-effect ft_14 mt_10' onClick={VerifyMailFucntion} >Proceed</a>
}                  {/* <div className='send_otp_btn'>
                    <input  type="number" class="validate" name='otp' placeholder='Enter OTP...' value={otp} onChange={(e) => Setotp(e.target.value)} required maxLength={6} />
                    <a className='pr_btn roboto_regular waves-effect ft_14 ' onClick={VerifyMailFucntion} >Proceed</a>
                  </div> */}
                </div>
              }
            </div>
            <div className="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Country<span class="redd">*</span></p>
              <select name='category' value={SelectedCountryId} onChange={HandelCountry} >
                <option value="" >{CountryName}</option>
                {Country && Country.map((option) => (
                  <option key={option.id} value={option.code} data-extra={option.currency_code}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}

            </div>
            <div className="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>State<span class="redd">*</span></p>
              <select name='category' value={selectedstate} onChange={HandelState} >
                <option value="" disabled selected>{StateName?StateName:"Select State"}</option>
                {state && state.map((option) => (
                  <option key={option.id} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}

            </div>

            <div className="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>City<span class="redd">*</span></p>
              <select name='city' value={SelectedCity} onChange={HandleCiity}  >
                <option value="" disabled >{CityName?CityName:'Select City'}</option>
                {CityData && CityData.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.city && (
                <span className="error-message">
                  {errors.city}
                </span>
              )}
            </div>

            <div className="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Address <span class="redd">*</span></p>
              <input id="icon_telephone" type="text" class="validate" name='location' value={location} onChange={(e) => Setlocation(e.target.value)} />
              {errors.location && (
                <span className="error-message">
                  {errors.location}
                </span>
              )}
            </div>

            <div className="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Tags</p>
              <input id="icon_telephone" type="text" class="validate" name='tags' value={tags} onChange={(e) => Settags(e.target.value)} />
             
            </div>
            {/* <!--premium --> */}
            <p className='ft_14 roboto_medium d_black mt_10'>Select Plan<span class="redd">*</span></p>
            <div className='d_flex l_gap mt_10'>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="0"
                    checked={make_premium === "0"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Normal Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Normal Charges: 0.1 Usd</p>
              </div>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="1" checked={make_premium === "1"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Premium Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Premium  Charges: 0.2 Usd</p>
              </div>
              {errors.make_premium && (
                <span className="error-message">
                  {errors.make_premium}
                </span>
              )}
            </div>
            <div className="mt_20">
              <label>
                <input type="checkbox" />
                <span name='agree_for_term' onClick={() => Setagree_for_term(1)}>I have read and agree to the Terms & Conditions</span>
              </label>
              {errors.agree_for_term && (
                <span className="error-message">
                  {errors.agree_for_term}
                </span>
              )}
            </div>
            <button className="mt_20 btn_full pr_btn roboto_regular waves-effect ft_14 mt_10" onClick={SubmitDetails}>
              <img src={Add} alt=" " className='btn_img' />
              Post an AD
            </button>
          </form>

        </div>
        <div className='right_a'></div>

      </div>
    </>
  )
}

export default AddProduct
