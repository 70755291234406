import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import share from '../../../images/share.svg'
import inactive from '../../../images/like_inactive.svg'
import location from '../../../images/location_light.svg'
import Edit from '../../../images/edit.svg'
import Delete from '../../../images/Delete.svg'
import timer from '../../../images/timer.svg'
import { BASE_URL } from '../../../Apis/config';
import { imageUrl } from '../../../Apis/config'
import Swal from 'sweetalert2';
const DashboardAdsContainer = (props) => {
      // convert html currency code
  function App({ htmlEntity }) {
    const decodeEntity = (entity) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = entity;
      return textArea.value;
    };

    return <>{decodeEntity(htmlEntity)}</>;
  }
      const navigate = useNavigate();
    const nav =useNavigate()
    const date = new Date(props.date);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
    const res=(pid,uid)=>{
        nav(`/edit/${pid}/${uid}`)
    }
    const success=(message)=>{
        Swal.fire({
          title: 'Product deleted  successfully',
          icon: "success"
        }).then((result) => {
          if (result.isConfirmed) {
              nav(`/`)
            };               });
      }
    const DeleteProd=(pid,uid)=>{
        fetch(`${BASE_URL}/posts/deleteMyListing/${pid}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id :uid
        })
      }).then((res)=>{
          return res.json();
      }).then((data)=>{
        if(data.success){
          success()  
        }
      }).catch((err)=>{
          console.log(err.message);   
      })
      }
      const redirect = () => {
        navigate(`/ProductDetails/${props.pid}`)
      }
    const imgarray = props.pic.split(",");
  const imgurl=`${imageUrl}/${imgarray[0]}`
    return (
        <div className="card_list  mt_10">
            <div className="card_n">
                <img
                src={imgurl}  
                onClick={redirect}
                alt="car"
                />
                <div className="c_details">
                <div className="like_share l_rsection">
                    <div className={props.css}>
                    <p className="ft_14 roboto_medium">{props.status}</p>
                    </div>
                    {/*featured*/}
                    <div className="like_share mt_20">
                    {/* <a  onClick={() => res(props.pid,props.uid)}>
                        <img src={Edit}alt={Edit} />
                    </a> */}
                    <a href='#' onClick={() => DeleteProd(props.pid,props.uid)}>
                        <img src={Delete} alt={Delete} />
                    </a>
                    </div>
                </div>
                <p className="ft_18 roboto_medium d_black"><App htmlEntity= {props.Currency} />{props.price}</p>
                <p className="ft_14 roboto_regular d_elblack">
                   {props.title}...
                </p>
                <p className="ft_14 roboto_regular d_elblack">
                    <img src={location}alt="location" />
                    {props.location}
                </p>
                <p className="ft_14 roboto_regular d_elblack">
                        <img src={timer}alt="location" />
                        Expiring on: {formattedDate}
                    </p>
                </div>
            </div>
            </div>
    )
}

export default DashboardAdsContainer