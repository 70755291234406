import React from 'react'
import logo from '../../images/logo.png'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
       <div className="footer_sec mt_20">
  <div className="footer">
    <div className='center'>
      <img src={logo} alt="logo" className="f_logo" />
      <p className="ft_14 roboto_regular d_lblack">
      Buy n Sell is the only solution for all your buying and selling needs. Sell any product like TV, Mobile Phone or Household item and avail a great price for that. 
      </p>
    </div>
    <div>
      {/* <p className="center roboto_medium d_black ft_18">Contact</p> */}
      {/* <div className="a_list ft_14 d_lblack">
        <Link className='d_blue'>Terms & Conditions</Link>
        <Link  className='d_blue'>Privacy Policy</Link>
        <Link className='d_blue'>FAQ</Link>
      </div> */}
    </div>
  </div>
  <div className="center btm_txt">
    <p className="ft_14 d_black roboto_regular">
      Copyright © 2024 buynsell. All Rights Reserved.
    </p>
  </div>
</div>

    </>
  )
}

export default Footer
