import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query';
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import locatin from '../../images/location_light.svg'
import { handleShare } from '../../Pages/Share'
import like from '../../images/liked.svg'
import axios from 'axios';
import { BASE_URL } from '../../Apis/config';
import { imageUrl } from '../../Apis/config'
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
const cookies = new Cookies();
const Ads = (props) => {
  const nav =useNavigate()
  // convert html currency code
  function App({ htmlEntity }) {
    const decodeEntity = (entity) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = entity;
      return textArea.value;
    };
    return <>{decodeEntity(htmlEntity)}</>;
  }
  const [Liked, setLiked] = useState(false);
  const queryClient = useQueryClient();
  const uid = cookies.get('Id');
  const navigate = useNavigate();

          const success1=(message)=>{
            Swal.fire({
              title: 'Product Liked successfully',
              icon: "success"
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                nav(`/`)
              }; 
            });;
          }
          const success = () => {
           window.location.reload()
          }
  const isLiked = (id) => {
    setLiked(!Liked);
    if(props.status=='liked'){
      fetch(`${BASE_URL}/removewishlist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id:props.pid,
            user_id :uid,
            post_id : id
      })
    }).then((res)=>{
        return res.text();
    }).then((data)=>{
      success();
    }).catch((err)=>{
        console.log(err.message);   
    })  
    }
    else{
      fetch(`${BASE_URL}/savewishlist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user_id :uid,
            post_id : id
      })
    }).then((res)=>{
        return res.text();
    }).then((data)=>{
    }).catch((err)=>{
        console.log(err.message);   
    })
    }
  };
  const redirect = () => {
    navigate(`/ProductDetails/${props.id}`)
  }
  const imgarray = props.pic.split(",");
  const imgurl=`${imageUrl}/${imgarray[0]}`
  const date = new Date(props.date);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
  return (
    <>
      {props.featured ==1&& <div className="card_list feature_c_details">
        <div className="card_n ">
          <img onClick={redirect} src={imgurl} alt={props.pic}/>
          <div className="c_details">
            <div className="like_share l_rsection">
              {/*featured*/}
              <div className="featured2">
                <p className="ft_14 roboto_medium">Featured</p>
              </div>
              {/*featured*/}
              <div className="like_share">
                <a onClick={handleShare}>
                  <img src={share} alt="share" />
                </a>
                {props.status=='liked' && <a   onClick={() => isLiked(props.id)}>
                  <img src={like} alt="Like" />
                </a>}
                {props.status!='liked' &&  <a   onClick={() => isLiked(props.id)}>
                  <img src={Liked ? like : inactive} alt="Like" />
                </a>
                
                }
              
              </div>
            </div>
            <div  onClick={redirect} className='randomclass'>
            <p className="ft_18 roboto_medium d_blue"> <App htmlEntity= {props.Currency} /> {props.price}</p>
            <p className="ft_14 roboto_regular d_lblack">Posted on: {formattedDate}</p>
            <p className="ft_14 roboto_regular d_elblack line_1">
              {props.title}
            </p>
            <p className="ft_14 roboto_regular d_elblack line_1">
              <img src={locatin} alt="location" />
              {props.location}
            </p>
            </div>
          </div>
        </div>
      </div>}
      {props.featured ==0&& <div className="card_list">
        <div className="card_n ">
          <img  onClick={redirect}src={imgurl}alt={props.pic} />
          <div className="c_details">
          <div className="like_share">
                <a onClick={handleShare}>
                  <img src={share} alt="share" />
                </a>
                <a onClick={() => isLiked(props.id)}>
                  <img src={Liked ? like : inactive} alt="Like" />
                </a>
              </div>
              <div  onClick={redirect} className='randomclass'>
              <p className="ft_18 roboto_medium d_blue mt_10"> <App htmlEntity= {props.Currency} />  {props.price}</p>
            <p className="ft_14 roboto_regular d_lblack"> Posted on: {formattedDate}</p>
            <p className="ft_14 roboto_regular d_elblack line_1">
              {props.title}
            </p>
            <p className="ft_14 roboto_regular d_elblack line_1">
              <img src={locatin} alt="location" />
              {props.location}
            </p>
              </div>
           
          </div>
        </div>
      </div>}


    </>
  )
}

export default Ads
