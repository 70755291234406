import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const GetCountry = async (BASE_URL,endPoint) => {
    try {
      const Url = `${BASE_URL}/${endPoint}`;
    const response = await fetch(Url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();
    console.log(jsonData.country)
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };
  