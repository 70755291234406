import React, { useEffect, useState } from 'react'
import Ads from '../Ads/Index'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Search from '../Header/Search';
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import location from '../../images/location_light.svg'
import { useLocation, useParams } from 'react-router-dom';
import { handleShare } from '../../Pages/Share'
import like from '../../images/liked.svg'
import Premiumad from '../Ads/Premiumad';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const CatDetails = (props) => {
const Currency = !cookies.get('Currency') ? "&#8358" : cookies.get('Currency');
const [FilteredData, SetFilteredData] = useState([])
const [Premium, SetPremium] = useState('')
const [Latest, SetLatest] = useState('')
const uid = cookies.get('Id');
const { slug, id, name } = useParams()
const location = useLocation();
const CountryCode = !(cookies.get('Country')) ? props.Country : cookies.get('Country');
const StateCode = cookies.get('Statevalue');
const City = cookies.get('CityId');
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(2); 
const [OfLimit, SetOfLimit] = useState(0); 
const itemsPerPage =2;
// Pagination handling
    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
        SetOfLimit(itemsPerPage*(page-1))
      }
    };
 // get data on based on category
const fetchData = async (page) => {
fetch(`${BASE_URL}/listingbycategorycustom`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    country_code: CountryCode,
    state_id: !(StateCode) ? '' : StateCode,
    city_id: !(City) ? '' : City,
    limit:itemsPerPage,
    offset: OfLimit,
    orderBy: "DESC",
    category_id: id
  })
}).then((res) => {
  return res.json();
}).then((data) => {
  if(data.result.latestPosts.length>0){
    setTotalPages((data.result.latestPosts.length))
  }
  else if(data.result.premiumPosts.length>0){
    setTotalPages((data.result.premiumPosts.length))
  }
  SetFilteredData(data.result)
  SetPremium(data.result.premiumPosts)
  SetLatest(data.result.latestPosts)
}).catch((err) => {
  console.log(err.message);
})
};

      useEffect(() => {
          fetchData(currentPage);
        } , [currentPage]);

  // const queryClient = useQueryClient();
  // const res = queryClient.getQueryData(['Related']);
  return (
    <>
      <div className="body_area">

        {/* {FilteredData && <p class="ft_18 mt_10 roboto_medium">Buy & Sell {name}</p>
        } */}
        {(Premium.length == 0 && Latest.length == 0) && <p className="ft_14 mt_10 roboto_regular d_lblack">
          No result Found
        </p>}

        {Premium.length > 0 && <div className="mt_20">
          <p className="ft_18 roboto_medium">Featured listing </p>
          <p className="ft_14 d_elblack roboto_regular">{name}</p>
        </div>}

        <div className="cards mt_10">
          {Premium && Premium.map((ele, i) => {
            return (
              <Premiumad pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
                location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Premiumad>
            )
          })
          }
        </div>

        {Latest.length > 0 && <div className="mt_20">
          <p className="ft_18 roboto_medium">Latest listing</p>
          <p className="ft_14 d_elblack roboto_regular">{name}</p>
        </div>}
        <div className="list_items mt_10">
          {Latest && Latest.map((ele, i) => {
            return (
              <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
                location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Ads>
            )
          })
          }
        </div>

    {Latest!=''&&<ul class="pagination">
        <li  className={`${currentPage === 1?'':'active'}`} ><a onClick={() => handlePageChange(currentPage-1)}
              disabled={currentPage === 1}>{`<< Previous`}</a></li>
           <li  className={`${currentPage === totalPages?'':'active'}`}  onClick={() => handlePageChange(currentPage+1)}
            disabled={currentPage === totalPages}><a >{`Next >>`}</a></li>
          </ul>}
      </div>
    </>
  )
}

export default CatDetails
