
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Dashboard from './components/Dashboard';
import Layout from './Layout';
import HeroSec from './components/HeroSec';
import CatDetails from './components/Categories/CatDetails';
import Payment from './Pages/Payment';
import { useEffect, useState } from 'react';
import Profile from './components/User/Profile';
import AddProduct from './components/Dashboard/Product/AddProduct';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from './Apis/config';
import ProductDetails from './components/Dashboard/Product/ProductDetails';
import EditPage from './Pages/EditPage';
import FavouriteProduct from './components/Dashboard/Product/FavouriteProduct';
import EditProfile from './components/User/EditProfile';
import PostPayment from './Pages/PostPayment';
import loader from '../src/images/loader.gif';
import Restrict from '../src/images/Restricted.png';
import { UserData } from '../src/Apis/HomePageApi';
import EditProduct from './components/Dashboard/Product/EditProduct';
import ViewAll from './components/Categories/ViewAll';
import SearchData from './components/Categories/SearchData';
const cookies = new Cookies();
// Get Minipay address
const generateRandomId = async() => {
  if (window && window.ethereum) {
    if (window.ethereum.isMiniPay) {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
       return accounts[0];
    }
    }
};
function App() {
  const [isLoading,SetisLoading]=useState(false)
  const [Country,SetCountry]=useState('')
  const [CountryName,SetCountryName]=useState('')
  const [Currency,SetCurrency]=useState('')
  const [CurrencySymbol,SetCurrencySymbol]=useState('')
  const queryClient = useQueryClient();
  const [showComponent, setShowComponent] = useState(false);
  const [Restricted, SetRestricted] = useState(false);
  const id = cookies.get('Id');
  // Function to register user
    useEffect(() => {
      ; (async () => {
        if(!id){
        const address = await generateRandomId()
        if(address){
        try {
          const endPoint='customsignup'
            const Res = await UserData(BASE_URL,!(address)?"0xF55A429a644164f336ed893485D53f35D077E44d":address,endPoint);
            SetCountry("NG")  
            SetCountryName("Nigeria") 
            SetCurrency("NGN")
            SetCurrencySymbol("&#8358")
            setShowComponent(true)
          }
               catch (error) {
          console.log(error)
        }
      }
      else{
        SetRestricted(true)
      }
  }
      else{
        SetCountry("NG")  
        SetCountryName("Nigeria")  
        SetCurrency("NGN")
        SetCurrencySymbol("&#8358")
        setShowComponent(true)
      }
      })()
    }, []);  
  return (  
    <>
     {!showComponent &&Restricted&&<div className='center_vh'><img src={Restrict}/></div>}
   {!showComponent &&!Restricted&&<div className='loader'><img src={loader}/></div>}
   {showComponent&&!Restricted&&<QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout/>}>
          <Route index element={<HeroSec id={id} Country={Country} CountryName={CountryName} Currency={Currency} CurrencySymbol={CurrencySymbol}/>} />
          <Route path="/SearchItem/:slug" element={<SearchData />}/>
          <Route path="/Categories/" element={<CatDetails id={id} />}/>
          <Route path="/Categories/:slug/:id/:name" element={<CatDetails id={id}Country={Country} Currency={Currency} CurrencySymbol={CurrencySymbol}  />}/>
          <Route path="/ProductDetails/:id" element={<ProductDetails />}/>
          <Route path="/Profile/" element={<Profile   />}/>
          <Route path="dashboard" element={<Dashboard   />}/>
          <Route path="MyListing" element={<Dashboard  />}/>
          <Route path="AddProduct" element={<AddProduct  id={id}  />}/>
          <Route path="Edit/:pid/:uid" element={<EditProduct   />}/>
          <Route path="Favourite" element={<FavouriteProduct/>}/>
          <Route path="ViewAll/:type" element={<ViewAll/>}/>
          <Route path="Payment/:id/:uid" element={<Payment/>}/>
          <Route path="PostPayment/:id/:uid/:ptype" element={<PostPayment/>}/>
          <Route path="EditProfile" element={<EditProfile/>}/>
          </Route>
        </Routes>
      </Router>
      </QueryClientProvider>}
    </>
  );
}
export default App;