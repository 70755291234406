import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const UserData = async (BASE_URL,Address,endPoint) => {
    try {
      const Url = `${BASE_URL}/${endPoint}`;
      console.log(Url)
    const rawData = {
        name : "User",
        username : Address,
        email :'',
        password: "Address",
        agree_for_term: "yes"  }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();
    if(!(cookies.get('Country'))){
    cookies.set('CountryName','Nigeria', { path: '/' });
    }
    cookies.set('Id', jsonData.result[0].id, { path: '/' });
    console.log(jsonData.country)
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };
  
  